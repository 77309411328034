<template>
    <div>
       <lb-datatableaction :headers="tableHeaders" :tabledata="taskid"></lb-datatableaction>
    </div>
</template>
<script>
export default {
    data(){
        return{
            taskid:'',
            tableHeaders: [
              {
                text: 'SL No',
                value: 'indexno',
                visible: true,
                filterable: false,
                sortable: false,
              },
              {
                value: "description",
                text: "Description",
                sortable: false,
                filterable: true,
                visible: true,
              },
              {
                value: "from",
                text: "Start Period",
                sortable: false,
                filterable: true,
                visible: true,
              },
              {
                value: "to",
                text: "End Period",
                sortable: false,
                filterable: true,
                visible: true,
              },
              {
                value: "stage",
                text: "Status",
                sortable: false,
                filterable: false,
                visible: false,
              },
              {
                value: "summary",
                text: "Tasklist Progress",
                sortable: false,
                filterable: false,
                visible: true,
              },
      
              {
                value: "created_at",
                text: "Created At",
                sortable: false,
                filterable: false,
                visible: false,
              },
              {
                value: "updated_at",
                text: "Updated At",
                sortable: false,
                filterable: false,
                visible: false,
              },
              {
                value: "action",
                text: "Action",
                sortable: false,
                filterable: false,
                visible: true,
              },
            ],
        }
    },
   created() {
    this.getProjectsData();
   },
    methods:{
            getProjectsData() {
            this.loading = true;
            this.axios
              .post("/v2/financialclose/project/get")
              .then((dt) => {
                if (dt.data.status === "success") {
                    console.log(dt.data.data,"dt.data.data");
                    this.taskid = dt.data.data[0];
                    this.$store.commit("setNewList", [this.taskid])
                     this.$nova.gotoLink({
                        path:
                          "/financialclose/project/checklist/" + this.taskid._id,
                      });
                  } else throw new Error(dt.data.message);

              })
              .catch((err) => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
              })
              .finally(() => {
                 this.loading = false;
              });
        
    },
    sortByCreatedAtDescending(data){
        return data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
}
}
</script>